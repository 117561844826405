import { apiGet } from 'emporia-shared-frontend'

const resourceName = 'project'
const resourcePath = '/project/summary'

export const getProjectData = (projectId: string, fetchType: string, exclusiveStartKey: string = undefined) => {
    return apiGet(resourceName, resourcePath, {
        queryStringParameters: {
            projectId,
            fetchType,
            ...(exclusiveStartKey && { exclusiveStartKey }),
        },
    })
}

export const getAllProjectData = () => {
    return apiGet(resourceName, resourcePath, {
        queryStringParameters: {
            projectId: 'all',
            fetchType: 'ProjectStatusAll',
        },
    })
}
