import { apiGet, apiPost, apiDel, apiPut, ScreenerObject } from 'emporia-shared-frontend'
import { Timeslot } from '../../models/Timeslot'
import { isEmpty } from 'lodash'

const resourceName = 'project'
const resourcePath = '/project'
const updateProjectPath = '/project/update'
const cloneProjectPath = '/project/clone'
const projectStatePath = '/project/state'
const surveyPath = '/project/survey'
const timeslotPath = '/project/timeslot'
const timeslotAvailabilityPath = '/project/timeslot/availability'
const projectBookingsPath = '/project/bookings'
const recommendedProjectsPath = '/project/recommended-projects'
const generateDescriptionPath = '/project/generate-description'
const generateQuestionsPath = '/project/generate-questions'
const feasibilityPath = '/project/feasibility'
const lineItemsPath = '/project/line-items'
const linkedProjectsPath = '/project/linked-projects'
const projectRespondentStatesPath = '/project/all-respondent-states'
const partnerQuotasPath = '/project/partner-quotas'
const audienceDefinitionPath = '/project/audience-definition'
const projectFilesPath = '/project/files'
const projectFilesDownloadPath = '/project/files/download'

export const createProject = (project) => {
    return apiPost(resourceName, resourcePath, {
        body: project,
    })
}

export const getProject = (projectId: string) => {
    return apiGet(resourceName, resourcePath, {
        queryStringParameters: {
            projectId,
        },
    })
}

export const getAudienceDefinitionModel = () => {
    return apiGet(resourceName, audienceDefinitionPath, {
        queryStringParameters: {},
    })
}

export const updateProjectState = (projectId, state, pauseReason = undefined) => {
    return apiPost(resourceName, projectStatePath, {
        body: {
            projectId,
            state,
            pauseReason,
        },
    })
}

export const cloneProject = (projectId) => {
    return apiPost(resourceName, cloneProjectPath, {
        body: {
            projectId,
        },
    })
}

export const updateProject = (project) => {
    return apiPut(resourceName, updateProjectPath, {
        body: project,
    })
}

export const deleteProject = (project) => {
    return apiDel(resourceName, resourcePath, {
        queryStringParameters: {
            projectId: project.id,
        },
    })
}

export const createScreener = (screener: ScreenerObject) => {
    return apiPost(resourceName, surveyPath, {
        body: screener,
    })
}

export const getLineItems = (lineItemIds: any, projectId: string) => {
    return apiPost(resourceName, lineItemsPath, {
        body: {
            lineItemIds,
            projectId,
        },
    })
}

export const updateLineItems = (lineItems: any) => {
    return apiPut(resourceName, lineItemsPath, {
        body: {
            lineItems,
        },
    })
}

export const getScreener = (projectId: string) => {
    return apiGet(resourceName, surveyPath, {
        queryStringParameters: {
            projectId,
        },
    })
}

export const getTimeslots = (projectId: string) => {
    return apiGet(resourceName, timeslotPath, {
        queryStringParameters: {
            projectId,
        },
    })
}

export const deleteTimeslot = (timeslotId: string, projectId: string) => {
    return apiDel(resourceName, timeslotPath, {
        queryStringParameters: {
            timeslotId,
            projectId,
        },
    })
}

export const createTimeslot = (timeslot: Timeslot) => {
    return apiPost(resourceName, timeslotPath, {
        body: {
            timeslot,
        },
    })
}

export const updateTimeslotAvailability = (
    timeslotId: string,
    projectId: string,
    contactId: string,
    available: boolean
) => {
    return apiPost(resourceName, timeslotAvailabilityPath, {
        body: {
            timeslotId,
            projectId,
            contactId,
            available,
        },
    })
}

export const getProjectBookings = (projectId: string) => {
    return apiGet(resourceName, projectBookingsPath, {
        queryStringParameters: {
            projectId,
        },
    })
}

export const getRecommendedProjects = (projectCode: string, contactId: string) => {
    const params = {}
    if (!isEmpty(projectCode)) {
        params['projectCode'] = projectCode
    } else if (!isEmpty(contactId)) {
        params['contactId'] = contactId
    }
    return apiGet(resourceName, recommendedProjectsPath, {
        queryStringParameters: {
            ...params,
        },
    })
}

export const generateDescription = (description: string) => {
    return apiPost(resourceName, generateDescriptionPath, {
        body: {
            description,
        },
    })
}

export const promptGpt = (messages) => {
    return apiPost(resourceName, generateQuestionsPath, {
        body: {
            messages,
        },
    })
}

export const requestFeasibilityUpdate = (teamId, projectId, manualReview = true) => {
    return apiPost(resourceName, feasibilityPath, {
        body: {
            teamId,
            projectId,
            manualReview,
        },
    })
}

export const changeProjectTeam = (projectId, newTeamId, newCreatorEmail) => {
    const runType = 'change_project_team'
    return apiPost(resourceName, resourcePath, {
        body: {
            runType,
            projectId,
            newTeamId,
            newCreatorEmail,
        },
    })
}

export const getLinkedProjects = (projectId: string) => {
    return apiGet(resourceName, linkedProjectsPath, {
        queryStringParameters: {
            projectId,
        },
    })
}

export const createdLinkedProject = (parentProjectId, linkedProjectId, linkingType) => {
    return apiPost(resourceName, linkedProjectsPath, {
        body: {
            parentProjectId,
            linkedProjectId,
            linkingType,
        },
    })
}

export const getRespondentStates = () => {
    return apiGet(resourceName, projectRespondentStatesPath, {})
}

export const getPartnerQuotaSummary = (projectId, partnerId, segmentId) => {
    return apiGet(resourceName, partnerQuotasPath, {
        queryStringParameters: {
            projectId,
            partnerId,
            segmentId,
        },
    })
}

export const getDownloadUrlForProjectFile = (objectKey) => {
    return apiGet(resourceName, projectFilesDownloadPath, {
        queryStringParameters: {
            objectKey,
        },
    })
}

export const getProjectFileReferences = (projectId) => {
    return apiGet(resourceName, projectFilesPath, {
        queryStringParameters: {
            projectId,
        },
    })
}

export const createProjectFileReference = (projectId, s3ObjectKey, fileName, fileSize) => {
    return apiPost(resourceName, projectFilesPath, {
        body: {
            projectId,
            s3ObjectKey,
            fileName,
            fileSize,
        },
    })
}

export const deleteProjectFileReference = (fileId, projectId) => {
    return apiDel(resourceName, projectFilesPath, {
        queryStringParameters: {
            fileId,
            projectId,
        },
    })
}
