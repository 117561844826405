import { Icon, Intent, Tag } from '@blueprintjs/core'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { getAllProjectData } from '@/clients/project/ProjectStatus'
import { convertFeasibilityTypeToLabel, Project } from 'emporia-shared-frontend'
import { cloneProject } from '@/clients/project/Project'
import Link from 'next/link'
import { routeForProjectDetails } from '../src/routing/ResearchDashboardRouteBuilder'
import { Alert, Flex, Grid, Group, Input, Title, createStyles, Stack, Menu, UnstyledButton } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { Button } from '@mantine/core'
import { Card } from '@mantine/core'
import { IconArrowRight, IconCopy, IconDots, IconInfoCircle, IconPlus, IconSearch } from '@tabler/icons-react'
import { Tabs } from '@mantine/core'
import { Skeleton } from '@mantine/core'

const useStyles = createStyles((theme) => ({
    card: {
        minHeight: 290,
        height: 290,
        overflow: 'visible',
    },
    container: {
        minHeight: '100vh',
        backgroundColor: theme.colors.customGreys[0],
        flexDirection: 'column',
        overflowY: 'hidden',
        padding: 50,
    },
}))

export default function Home() {
    const router = useRouter()
    const [projectData, setProjectData] = useState<Array<any>>([])
    const [isLoading, setIsLoading] = useState<Boolean>(true)
    const [searchText, setSearchText] = useState<string>('')
    const { classes } = useStyles()

    useEffect(() => {
        loadProjects()
    }, [])

    const loadProjects = async () => {
        setIsLoading(true)
        getAllProjectData().then(async (projects: Project[]) => {
            setProjectData(projects)
            setIsLoading(false)
        })
    }

    const styles = {
        cardTopRow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        closeIcon: {
            position: 'absolute',
            right: 20,
            cursor: 'pointer',
        },
        emptyContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: 500,
        },
        icon: {
            marginRight: 7,
            color: '#929fa6',
        },
        iconRow: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 9,
        },
        infoText: {
            color: '#6c797e',
        },
        popover: {
            border: 'solid white 0px',
            borderWidth: 0,
            boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.3)',
            overflowY: 'hidden',
        },
    } as const

    const handleCloneProject = (project) => {
        if (!window.confirm('Are you sure?')) {
            return
        }
        notifications.show({
            color: 'blue',
            title: 'Loading',
            message: 'Cloning your project as a new draft...',
        })

        const projectId = project['id'].replace('project#', '')
        cloneProject(projectId)
            .then(() => {
                notifications.show({
                    color: 'green',
                    title: 'Success',
                    message: 'Your project has been cloned.',
                })
                loadProjects()
            })
            .catch((error) => {
                notifications.show({
                    color: 'red',
                    title: 'Error',
                    message:
                        'An error occurred while cloning your project. Please try again' +
                        ' and contact support if the issue continues.',
                })
            })
    }

    const renderStateIntent = (project) => {
        if (project.state == 'ACTIVE') {
            return Intent.SUCCESS
        }
        if (project.state == 'DRAFT') {
            return Intent.PRIMARY
        }
        if (project.state == 'PAUSED') {
            return Intent.DANGER
        } else {
            return Intent.NONE
        }
    }

    const renderEmptyState = () => {
        return (
            <div style={styles.emptyContainer}>
                <Icon color='#bbbbbb' icon='projects' size={50} />
                <h2 style={{ marginTop: 15, color: '#606060' }}>No Projects</h2>
                <p style={styles.infoText}>Create a new project to get started.</p>
            </div>
        )
    }

    const renderProjectCards = (projectState) => {
        projectData.sort((a: Project, b: Project) => {
            if (a.state == b.state) {
                return a.pid.localeCompare(b.pid)
            }

            if (a.state === 'ACTIVE') {
                return -1
            }

            if (a.state === 'DRAFT' && b.state != 'ACTIVE') {
                return -1
            }

            if (a.state === 'PAUSED' && b.state != 'ACTIVE' && b.state != 'DRAFT') {
                return -1
            }

            return 1
        })

        var renderedCards = 0
        return (
            <Grid>
                {isLoading &&
                    [...Array(5)].map((item, index) => (
                        <Grid.Col lg={3} md={4} sm={6} xs={12} key={`skeleton-${index}`} span={3}>
                            <Card className={classes.card}>
                                <Skeleton width='100%' height={20} />
                                <Skeleton mt={15} width='90%' height={20} />
                                <Skeleton mt={15} width='70%' height={20} />
                                <Skeleton mt={15} width='80%' height={20} />
                            </Card>
                        </Grid.Col>
                    ))}
                {projectData.map((project, index) => {
                    if (projectState != project.state && projectState != 'ALL') {
                        return
                    }

                    if (!searchText || project.pid.toLowerCase().includes(searchText.toLowerCase())) {
                        renderedCards += 1
                        return (
                            <Grid.Col lg={3} md={4} sm={6} xs={12} key={`project-${project.id}`}>
                                <Card className={classes.card} shadow='md' withBorder>
                                    <Stack spacing={0} sx={{ minHeight: '100%' }}>
                                        <div style={styles.cardTopRow}>
                                            <Tag
                                                style={{ width: 80 }}
                                                minimal={true}
                                                intent={renderStateIntent(project)}
                                            >
                                                <p
                                                    style={{
                                                        textAlign: 'center',
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    {project.state}
                                                </p>
                                            </Tag>
                                            <Menu shadow='md'>
                                                <Menu.Target>
                                                    <UnstyledButton color='gray' variant='light'>
                                                        <IconDots size={18} />
                                                    </UnstyledButton>
                                                </Menu.Target>
                                                <Menu.Dropdown>
                                                    <Menu.Item
                                                        onClick={() => handleCloneProject(project)}
                                                        icon={<IconCopy size={14} />}
                                                    >
                                                        Clone Project
                                                    </Menu.Item>
                                                </Menu.Dropdown>
                                            </Menu>
                                        </div>
                                        <h3 className='project-name-truncated'>{project.pid}</h3>
                                        <div style={styles.iconRow}>
                                            <Icon style={styles.icon} icon='clipboard' />
                                            <p style={styles.infoText}>
                                                Type: {project.studyType === 'quant' ? 'Quant' : 'Qualitative'}
                                            </p>
                                        </div>
                                        {project.created_at && (
                                            <div style={styles.iconRow}>
                                                <Icon style={styles.icon} icon='time' />
                                                <p style={styles.infoText}>
                                                    Created: {new Date(project.created_at).toLocaleDateString()}
                                                </p>
                                            </div>
                                        )}
                                        <div style={styles.iconRow}>
                                            <Icon style={styles.icon} icon='series-search' />
                                            <p style={styles.infoText}>
                                                Feasibility:{' '}
                                                {convertFeasibilityTypeToLabel(project.feasibility) ||
                                                    (project.nSize ? `n=${project.nSize}` : 'pending...')}
                                            </p>
                                        </div>
                                        <div style={styles.iconRow}>
                                            <Icon style={styles.icon} icon='people' />
                                            <p style={styles.infoText}>Target Recruits: {project.target_completes}</p>
                                        </div>
                                        <Flex
                                            sx={{
                                                justifyContent: 'flex-end',
                                                alignItems: 'flex-end',
                                                flex: 1,
                                            }}
                                        >
                                            <Link prefetch={false} href={routeForProjectDetails(project.id)}>
                                                <Button
                                                    sx={(theme) => ({
                                                        border: `1px solid ${theme.colors.customGreys[1]}`,
                                                    })}
                                                    rightIcon={<IconArrowRight size={18} />}
                                                    color='gray'
                                                    variant='light'
                                                >
                                                    View Details
                                                </Button>
                                            </Link>
                                        </Flex>
                                    </Stack>
                                </Card>
                            </Grid.Col>
                        )
                    }
                })}
                {renderedCards === 0 && !isLoading && renderEmptyState()}
            </Grid>
        )
    }

    return (
        <Flex className={classes.container}>
            <Group mb={20} sx={{ width: '100%', justifyContent: 'space-between' }}>
                <Group spacing={25}>
                    <Title order={2}>Projects</Title>
                    <Input
                        icon={<IconSearch size={18} />}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        sx={{ width: 280 }}
                        placeholder='Search for a project...'
                    />
                </Group>
                <Button leftIcon={<IconPlus size={18} />} onClick={() => router.push('/create/project')}>
                    Create New Project
                </Button>
            </Group>
            <Tabs defaultValue='ALL'>
                <Tabs.List mb={20} sx={{ width: 350 }}>
                    <Tabs.Tab value='ALL'>All</Tabs.Tab>
                    <Tabs.Tab value='ACTIVE'>Active</Tabs.Tab>
                    <Tabs.Tab value='DRAFT'>Draft</Tabs.Tab>
                    <Tabs.Tab value='PAUSED'>Paused</Tabs.Tab>
                    <Tabs.Tab value='CLOSED'>Closed</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value='ALL'>{renderProjectCards('ALL')}</Tabs.Panel>
                <Tabs.Panel value='ACTIVE'>{renderProjectCards('ACTIVE')}</Tabs.Panel>
                <Tabs.Panel value='DRAFT'>{renderProjectCards('DRAFT')}</Tabs.Panel>
                <Tabs.Panel value='PAUSED'>{renderProjectCards('PAUSED')}</Tabs.Panel>
                <Tabs.Panel value='CLOSED'>{renderProjectCards('CLOSED')}</Tabs.Panel>
            </Tabs>
            <Alert mt={50} icon={<IconInfoCircle />} sx={{ width: 500, alignSelf: 'center' }} title={'Need help?'}>
                Check out our{' '}
                <a rel='noopener noreferrer' target='_blank' href={'https://intercom.help/emporia/en/'}>
                    support center
                </a>{' '}
                or{' '}
                <a
                    rel='noopener noreferrer'
                    target='_blank'
                    href={'https://calendly.com/d/dxk-hj5-pc2/emporia-training-session'}
                >
                    schedule a training session
                </a>
                .
            </Alert>
        </Flex>
    )
}
